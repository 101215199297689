// src/TermosDeUso.js
import React from "react";
import { Box, Heading, Text } from "@chakra-ui/react";

function TermosDeUso() {
  return (
    <Box
      textAlign="left"
      mt={10}
      mx="auto"
      maxW="800px"
      p={8}
      bg="gray.700"
      borderRadius="md"
      boxShadow="lg"
      color="white"
    >
      <Heading mb={6}>
        Política de Privacidade do Aplicativo da Jackson Preparações de Cabelo
      </Heading>

      <Text fontSize="lg" mb={4}>
        Última atualização: 15/11/2023
      </Text>

      <Text>
        A Jackson Preparações de Cabelo, responsável pelo aplicativo móvel de
        agendamento e serviços de barbearia, está comprometida em proteger a
        privacidade dos usuários. Esta Política de Privacidade descreve como
        coletamos, usamos, divulgamos e protegemos as informações pessoais dos
        usuários.
      </Text>

      <Text mt={4} fontWeight={"bold"} size={20}>
        1. Informações Coletadas:
      </Text>

      <Text mt={4} fontWeight={"bold"}>
        1.1. Informações de Registro:
      </Text>
      <Text>
        Coletamos informações de registro, como nome, endereço de e-mail, número
        de telefone e outras informações necessárias para criar e gerenciar uma
        conta de usuário.
      </Text>

      <Text mt={4} fontWeight={"bold"}>
        1.2. Informações de Pagamento:
      </Text>
      <Text>
        Ao utilizar serviços que requerem pagamento, poderemos coletar
        informações de pagamento, como dados de cartão de crédito ou outras
        formas de pagamento.
      </Text>

      <Text mt={4} fontWeight={"bold"}>
        1.3. Informações de Localização:
      </Text>
      <Text>
        Podemos coletar informações de localização quando o usuário utiliza
        recursos que exigem acesso à localização, como agendamento com base na
        proximidade.
      </Text>

      <Text mt={4} fontWeight={"bold"}>
        1.4. Informações de Uso:
      </Text>
      <Text>
        Registramos dados sobre o uso do aplicativo, como as páginas visitadas,
        o tempo gasto no aplicativo e as interações realizadas.
      </Text>

      <Text mt={4} fontWeight={"bold"} size={20}>
        2. Uso das Informações:
      </Text>

      <Text mt={4} fontWeight={"bold"}>
        2.1. Prestação de Serviços:
      </Text>
      <Text>
        Utilizamos as informações coletadas para oferecer e melhorar os serviços
        da barbearia, incluindo agendamento, confirmação de serviços e
        comunicações relacionadas.
      </Text>

      <Text mt={4} fontWeight={"bold"}>
        2.2. Comunicações:
      </Text>
      <Text>
        Podemos enviar comunicações relacionadas aos serviços, como confirmações
        de agendamento, lembretes ou informações sobre novos serviços.
      </Text>

      <Text mt={4} fontWeight={"bold"}>
        2.3. Análise e Melhoria:
      </Text>
      <Text>
        Utilizamos dados de uso para analisar o desempenho do aplicativo,
        identificar áreas de melhoria e otimizar a experiência do usuário.
      </Text>

      <Text mt={4} fontWeight={"bold"}>
        2.4. Marketing:
      </Text>
      <Text>
        Com consentimento, podemos enviar comunicações de marketing sobre
        serviços, promoções ou eventos especiais.
      </Text>

      <Text mt={4} fontWeight={"bold"} size={20}>
        3. Compartilhamento de Informações:
      </Text>

      <Text mt={4} fontWeight={"bold"}>
        3.1. Parceiros de Serviço:
      </Text>
      <Text>
        Podemos compartilhar informações com terceiros que prestam serviços em
        nosso nome, mas apenas conforme necessário para a prestação desses
        serviços.
      </Text>

      <Text mt={4} fontWeight={"bold"}>
        3.2. Requisitos Legais:
      </Text>
      <Text>
        Podemos divulgar informações pessoais em resposta a processos judiciais
        ou por solicitação de autoridades governamentais.
      </Text>

      <Text mt={4} fontWeight={"bold"} size={20}>
        4. Segurança:
      </Text>
      <Text>
        Implementamos medidas de segurança para proteger as informações dos
        usuários contra acesso não autorizado, alteração, divulgação ou
        destruição não autorizada.
      </Text>

      <Text mt={4} fontWeight={"bold"} size={20}>
        5. Seus Direitos:
      </Text>
      <Text>
        Os usuários têm o direito de acessar, corrigir, atualizar ou excluir
        suas informações pessoais a qualquer momento. Para exercer esses
        direitos, entre em contato conosco através dos meios fornecidos no
        aplicativo.
      </Text>

      <Text mt={4} fontWeight={"bold"} size={20}>
        6. Alterações na Política de Privacidade:
      </Text>
      <Text>
        Reservamo-nos o direito de modificar esta Política de Privacidade a
        qualquer momento. As alterações serão comunicadas aos usuários através
        do aplicativo ou outros meios apropriados. Ao utilizar o aplicativo da
        Jackson Preparações de Cabelo, os usuários concordam com os termos desta
        Política de Privacidade.
      </Text>

      <Text mt={4} fontWeight={"bold"} size={20}>
        Contato:
      </Text>
      <Text>
        Para dúvidas ou preocupações relacionadas à privacidade, entre em
        contato conosco em jftechno.oficial@gmail.com.
      </Text>
    </Box>
  );
}

export default TermosDeUso;

// src/App.js
import React from "react";
import { ChakraProvider, CSSReset, extendTheme } from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TermosDeUso from "./TermosDeUso";
import Inicio from "./Inicio";
import Ajuda from "./Ajuda";
const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: "gray.800",
        color: "white",
      },
    },
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <CSSReset />
        <Routes>
          <Route path="/" element={<Inicio />} />
          <Route path="/termos-de-uso" element={<TermosDeUso />} />
          <Route path="/help" element={<Ajuda />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;

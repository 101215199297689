// src/App.js
import React from "react";
import {
  ChakraProvider,
  Box,
  Button,
  CSSReset,
  extendTheme,
  Image,
} from "@chakra-ui/react";
import { FaAndroid, FaApple } from "react-icons/fa";
import logo from "./logo.png";

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: "gray.800",
        color: "white",
      },
    },
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <CSSReset />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Image maxH={200} maxW={200} src={logo} alt="Logo" mb={4} />

        <Button
          as="a"
          href="https://play.google.com/store/apps/details?id=com.jftechno.JPCBarbearia"
          target="_blank"
          rel="noopener noreferrer"
          colorScheme="green"
          size="lg"
          mb={4}
          leftIcon={<FaAndroid />}
          maxW={235}
        >
          Baixar na Google Play
        </Button>
        <Button
          as="a"
          href="https://apps.apple.com/pt/app/jackson-prepara%C3%A7%C3%B5es-de-cabelo/id6472741542"
          target="_blank"
          rel="noopener noreferrer"
          colorScheme="blue"
          size="lg"
          leftIcon={<FaApple />}
          maxW={235}
        >
          Baixar na App Store
        </Button>
      </Box>
    </ChakraProvider>
  );
}

export default App;

// src/TermosDeUso.js
import React from "react";
import { Box, Heading, Text } from "@chakra-ui/react";

function TermosDeUso() {
  return (
    <Box
      textAlign="left"
      mt={10}
      mx="auto"
      maxW="800px"
      p={8}
      bg="gray.700"
      borderRadius="md"
      boxShadow="lg"
      color="white"
    >
      <Heading mb={6}>Ajuda</Heading>

      <Text fontSize="lg" mb={4}>
        Última atualização: 06/12/2023
      </Text>

      <Text>
        Bem-vindo à nossa página de contato! Estamos ansiosos para ouvir de você
        e ajudar no que for necessário. Sinta-se à vontade para nos enviar suas
        dúvidas, sugestões ou comentários
      </Text>

      <Text mt={4} fontWeight={"bold"} size={20}>
        1. Informações de Contato:
      </Text>

      <Text>
        Se preferir, entre em contato conosco diretamente pelos meios listados
        abaixo. Estamos disponíveis por e-mail jftechno.oficial@gmail.com.
      </Text>

      <Text mt={4} fontWeight={"bold"}>
        1.2. Horário de Atendimento:
      </Text>
      <Text>
        Nosso horário de atendimento é de segunda a sexta, das 08:00 às 18:00.
        Responderemos a todas as mensagens recebidas durante esse período no
        mesmo dia útil.
      </Text>

      <Text mt={4} fontWeight={"bold"} size={20}>
        2. Excluir Conta no Aplicativo:
      </Text>

      <Text>
        Para excluir a conta basta seguir os passos abaixo:
        <br />
        <Text>1 - Realizar o login no Aplicativo</Text>
        <Text>2 - Ir para pagina Perfil</Text>
        <Text>3 - Localize o botão Excluir Conta</Text>
        <br />
        Após confirmar que deseja excluir sua conta, todos os seus dados
        registrado serão excluidos do aplicativo
      </Text>
    </Box>
  );
}

export default TermosDeUso;
